/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Sidebar from './Sidebar';

const Bio = () => {
  return (
    <>
      <h2>MOMENTVM Documentation</h2>
      <p>This is the official documentation for MOMENTVM Content Cloud for Salesforce Commerce Cloud.</p>
    </>
  );
};

export default Bio;

import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import '../components/global.css';

const ScrollBox = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  & > div {
    background-color: #ff7e00;
    width: 100%;
    height: 3px;
    z-index: 99999;
    transform-origin-x: 0%;
  }
`;

const Wrapper = styled.div`
  h1 {
    font-size: 3rem;
    font-weight: 900;
  }
`;

interface Props {
  data: any;
  pageContext: any;
  location: Location;
}
const BlogPostTemplate = ({ data, pageContext, location }: Props) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const { scrollYProgress } = useViewportScroll();
  const boxWidth = useTransform(scrollYProgress, [0, 1], [0, 1]);
  return (
    <Wrapper>
      <Layout location={location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
        <h1
          style={{
            marginBottom: 0,
          }}
        >
          {post.frontmatter.title}
        </h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <p
          style={{
            display: 'block',
            paddingTop: '4rem',
          }}
        >
          Last updated {post.frontmatter.date}
        </p>
        <hr style={{}} />
        <Bio />
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
      <ScrollBox>
        <motion.div
          style={{
            scaleX: boxWidth,
          }}
        />
      </ScrollBox>
    </Wrapper>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
